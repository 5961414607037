<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup class="card-sdw " >
            <CCard 
                color="dark"
                text-color="white"
                class="py-input card-checkin"
                body-wrapper
            >
              <CCardBody class="test-card">
                <h4>กรุณากรอก pincode การสอบ</h4>
                <CRow>
                  <CCol >
                    <h6>( ขอ pincode เข้าสอบจากผู้คุมสอบ )</h6>
                  </CCol >
                </CRow>
                <br>
                <!-- <CRow>
                  <CCol class="text-center">
                    <CodeInput :loading="false" :fieldWidth="inputPinWidth" :fieldHeight="inputPinHeight"  className="test-card" class="input" v-on:complete="onComplete" />
                  </CCol>
                </CRow> -->
                <CRow>
                  <CCol class="text-center">
                    <div style="display: flex; flex-direction: row;justify-content: center;">
                      <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        input-type="number"
                        @on-complete="onComplete"
                      />
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard
              class="text-center py-7 d-md-down-none card-checkin"
              body-wrapper
            >
              <CCardBody>
                <h4>คุณเข้าสู่ระบบในชื่อ</h4><br>
                <div class="c-avatar c-avatar-xl bg-secondary">
                    <CIcon :height="42" name="cil-user"/>
                </div>
                <div class="pt-3">
                    <h3 class="animated"><b>{{displayName}}</b></h3>
                    <CRow>
                      <CCol sm="3" class="text-right">
                        tel:
                      </CCol>
                      <CCol sm="6" class="text-center">
                        {{ studentProfile.telephone }}
                      </CCol>
                      <CCol sm="3" class="text-left">
                        <a @click="updateStudentProfileData('telephone')">
                          <i class="fas fa-pen-square"></i>
                        </a>
                      </CCol>
                    </CRow>
                </div>
                <div class="pt-3">
                    <CButton color="outline-danger" size="sm" class="" @click="$router.push({path: '/signout'})">
                      log out
                    </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      title="รหัสผิดพลาด"
       color="danger"
      :show.sync="eventOnPincode"
    >
    <h5> pincode : {{pincode}} </h5>
    <h6>รหัสเพื่อทำการเข้า checkin ทำข้อสอบของคุณผิดพลาด </h6>
    <h6>กรุณาตรวจสอบรหัสผ่านของการเข้าสอบ</h6>
    <template #footer>
      <!-- <CButton @click="studentCheckOut(userEmail)" color="danger">Ok</CButton> -->
      <CButton @click="eventOnPincode = false" color="secondary">Cancel</CButton>
    </template>
    </CModal>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import CodeInput from "vue-verification-code-input"
import OtpInput from "@bachdgvn/vue-otp-input"
import apiExamManager from '../../services/exam-manager-api-services'

export default {
  name: 'InputCode',
  components: {
    CodeInput,
    OtpInput
  },
  data(){
    return {
      userEmail:localStorage.getItem('email'),
      displayName: localStorage.getItem('name'),
      windowWidth: window.innerWidth,
      inputPinWidth: 38 ,
      inputPinHeight: 34 ,
      eventOnPincode: false,
      pincode: null,
      txt: "",
      studentProfile: "",
    }
  },
  async created() {
    document.title = "Student Profile"
    this.getProfileStudent()
    await this.onResize()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  watch: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      if(this.windowWidth > 300 && this.windowWidth < 321){
        this.inputPinWidth  = 38
        this.inputPinHeight = 28
      }
      else if(this.windowWidth > 321 && this.windowWidth < 376){
        this.inputPinWidth  = 45
        this.inputPinHeight = 35
      }
      else if(this.windowWidth > 376 && this.windowWidth < 415){
        this.inputPinWidth  = 52
        this.inputPinHeight = 40
      } else if(this.windowWidth > 577 && this.windowWidth < 767){
        this.inputPinWidth  = 75
        this.inputPinHeight = 65
      } else if(this.windowWidth > 767 && this.windowWidth < 800){
        this.inputPinWidth  = 65
        this.inputPinHeight = 55
      }else if(this.windowWidth > 800 && this.windowWidth < 1260){
        this.inputPinWidth  = 41.5
        this.inputPinHeight = 30
      }else if(this.windowWidth > 1260 && this.windowWidth < 1400){
        this.inputPinWidth  = 51.5
        this.inputPinHeight = 40
      }
    },
    async onComplete(pincode) {
      this.pincode = pincode
      let eventData = await apiExamManager.getEventUsingPincode(pincode)
      if(eventData['data']){
        let eventid = eventData['data']['_id']
        await this.toGoCheckinPage(eventid)
      }else {
        this.eventOnPincode = true
        // Swal.fire({
        //   icon: 'error',
        //   title: 'ไม่พบข้อมูล',
        // })
      }
    },
    async toGoCheckinPage(eventid){
      this.$router.push({ path: `code/${eventid}` })
    },    
    async updateStudentProfileData (caseUpdate) {
      console.log(caseUpdate)
      switch (caseUpdate) {
        case "telephone":
          Swal.fire({
            title: "กรอกเบอร์โทรศัพท์",
            html: "ใช้สำหรับกรณีที่อาจารย์ต้องการติดต่อนักศึกษา",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Add",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: (phoneNumber) => {
              let updateDate = {
                "telephone" : phoneNumber
              }
              return apiExamManager
                .saveStudentProfile(this.userEmail, updateDate)
                .then((response) => {
                  console.log(response)
                  if (!response.data) {
                    throw new Error("Fail")
                  }
                  return response
                })
                .catch((error) => {
                  Swal.showValidationMessage(`Request failed: ${error}`)
                })
            },
            // allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            console.log("Result: ", result)
            if (result.isConfirmed) {
              Swal.fire("success", "เพิ่มเบอร์โทรศัพท์เรียบร้อย", "success")
              this.getProfileStudent()
            }
          })
          break;
        default:
          break;
      }
    },
    async getProfileStudent() {
      try {
        let studentProfile = await apiExamManager.getStudentProfile(this.userEmail)
        console.log("studentProfile ", studentProfile)
        this.studentProfile = studentProfile["data"]
        return studentProfile
      } catch (error) {
        console.log(`error-getProfileStudent-msg-:${error.message}`)
      }
    },
  }
}
</script>

<style scoped>

.test-card  {
  text-align: center !important;
}

.py-input {
  padding-top: 8rem !important;
}
.error {
  border: 1px solid red !important;
}
</style>>
